import React from 'react'

const Accordion = React.lazy(() => import('@mui/material/Accordion'))
const AccordionSummary = React.lazy(() => import('@mui/material/AccordionSummary'))
const AccordionDetails = React.lazy(() => import('@mui/material/AccordionDetails'))
const ExpandMoreIcon = React.lazy(() => import('@mui/icons-material/ExpandMore'))

const FAQ = ({ questions, heading = 'Learn More', props}) => {

  return (
    <section>
      <div className={'container'}>
        <React.Suspense>
          <h3>{heading}</h3>
          {questions.map(question => {
            return (
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header">
                  <p style={{ fontWeight: 600 }}>
                    {question?.qa?.question}
                  </p>
                </AccordionSummary>
                <AccordionDetails>
                  <p>
                    {question?.qa?.answer}
                  </p>
                </AccordionDetails>
              </Accordion>
            )
          })}
        </React.Suspense>
      </div>
    </section>
  )
}

export default FAQ