import React, { lazy, Suspense, useEffect, useState } from "react"
import { graphql } from "gatsby"

import { TemplateContextProvider } from 'components/post/TemplateProvider'
import { PlacesContextProvider } from 'components/post/PlacesProvider'

import {
  getCity,
  locationStore,
} from 'state/store-zustand'

import usePageLoading from 'components/utils/usePageLoading'
import useContextTheme from 'components/utils/useContextTheme'

import SEO from "components/seo"
import Header from "components/header"
import Sections from "components/sections"
import FAQ from "components/elements/faq"
import Footer from "components/footer"

const ActionsArea = lazy(() => import("components/modal/actionsArea"))

import { eventsToSchema, faqToSchema, placesToSchema } from 'components/utils/schemaUtils.js'

import "styles/pages.scss"
import "styles/city.scss"

// NOTE combined with post.scss: import "components/sections/impactArea/impactArea.scss"

const CityTemplate = (props) => {

  const { pageContext, data, location } = props

  let {
    cityDetails,
    faq,
    events,
    places,
    posts,
    title
  } = pageContext

  const activityCategories = Object.keys(places)
  const placesFlat = activityCategories.flatMap(activity => places[activity])
  //console.log(`Preloaded data `, places, activityCategories );

  const setCityCurrent = locationStore(state => state.setCityCurrent)
  const setHasLocation = locationStore(state => state.setHasLocation)

  // Use pre-load places for SEO
  // TODO: Trim/reduce the number of places to like 50
  // TODO: concat guides
  const initialSchemaData = (placesFlat && placesFlat.length > 0)
    ? placesToSchema(placesFlat)
      .concat(faq && faq.faq
        ? faqToSchema(faq.faq)
        : []
          .concat(events && events.features
            ? eventsToSchema(events.features)
            : [])
      )
    : []

  const [schemaPlaces, setSchemaPlaces] = useState(initialSchemaData)

  // Keep track of city slug
  const [citySlug, setCitySlug] = useState(cityDetails.slug || 'oakland')
  // And set full city object in state
  useEffect(() => {
    // do async check
    (async () => {
      if (citySlug) {
        const cityObject = await getCity(citySlug)
        setCityCurrent(cityObject)
        setHasLocation(true)
      }
    })()
  }, [citySlug])

  const [canonical, setCanonical] = useState(location.origin + location.pathname)
  const [embedded, setEmbedded] = useState(false)
  const pageData = data.allWpPage.edges[0].node

  const textColor = 'black'

  let geoQuery = null
  geoQuery = cityDetails.placemarker

  if (cityDetails.title) {
    geoQuery.city = cityDetails.title
  }

  const keywords = "Vibes, Things to do, Events, Neighborhoods, & Places to Stay"

  const cityName = cityDetails.title

  let seo = pageData.seo
  seo.title = `${cityName} | ${keywords}  | Vibemap`
  seo.opengraphTitle = seo.title
  seo.metaDesc = `Explore vibes in ${cityName}`

  const sectionData = pageData.sections.sections.map(data => {

    if (data.fieldGroupName === "Page_Sections_Sections_TextWithImage") {
      data.heading = cityDetails.subheading ? cityDetails.subheading : title
      data.bodyText = cityDetails.description
      data.lazyLoad = false

      if (cityDetails.photos && cityDetails.photos.length > 0) {
        data.image = cityDetails.photos[0]
      }

      if (cityDetails.vibeset) {
        data.vibeset = cityDetails.vibeset
      }
    }

    if (data.fieldGroupName === "Page_Sections_Sections_PostCardsBlock") {
      data.posts = posts
    }

    if (data.fieldGroupName === "Page_Sections_Sections_SingCardsBlock") {
      const signCardsOptions = data.singCards.posts[0]
      const activityQuery = signCardsOptions.activityQuery && signCardsOptions.activityQuery.length > 0
        ? signCardsOptions.activityQuery[0].slug
        : null

      data.singCards.places = activityQuery
        ? places[activityQuery]
        : data.singCards.places // Usually undefined

      data.singCards.events = events
        ? events
        : []
    }

    // Override section templates with City Details
    if (data.fieldGroupName === "Page_Sections_Sections_SingCardsBlock") {

      data.singCards.heading = data.singCards.heading.replace('{city}', cityDetails.title)

      if (data.singCards.cssClass === 'vibeCarousel' && cityDetails.vibe) {
        data.singCards.subheading = cityDetails.vibe
      }
    }

    return data
  })

  const pageLoader = usePageLoading()
  const theme = useContextTheme()

  const pageClass = `page city ${pageLoader} ${embedded ? 'embedded' : ''} ${theme ?  theme : ''}`

  return (
    <div className={pageClass}>
      <TemplateContextProvider
        city={cityDetails}
        citySlug={citySlug}
        setURLParams={false}>
        <PlacesContextProvider hasMap={true}>
          <SEO
            lang="en-US"
            title={seo.title}
            canonical={canonical}
            data={pageData.seo}
            schemaData={schemaPlaces} />
          <main>
            <Header city={cityDetails.title} />

            <section>
              <div className="s-impact-area">
                <div className="container">
                  <span className="category">
                    {cityDetails.placemarker.state} — {cityDetails.placemarker.country}
                  </span>
                  <h1 className={`title c-${textColor}`}>
                    {title}
                  </h1>
                </div>
              </div>
            </section>

            {/* Add SSR fallback */}
            <Suspense fallback={<div>Loading</div>}>
              <ActionsArea />
            </Suspense>

            <section className='body'>
              <div className="post-body">
                {pageData.sections.sections &&
                  <Sections
                    data={sectionData}
                    overrideQuery={{
                      location: geoQuery,
                      cities: [citySlug]
                    }}
                  />
                }
                {faq?.faq &&
                  <FAQ questions={faq.faq} />
                }
              </div>
            </section>

        </main>
        <Footer />
        </PlacesContextProvider>
      </TemplateContextProvider>
    </div>
  )
}

CityTemplate.whyDidYouRender = true
export default CityTemplate

export const cityQuery = graphql`
query cityQuery {
  allWpPage(filter: {slug: {in: "city-page-template"}}) {
    edges {
      node {
        id
        title
        slug
        status
        date
        pageDetails {
          pageActivities {
            slug
          }
        }
        seo {
          title
          opengraphAuthor
          focuskw
          canonical
          opengraphDescription
          metaDesc
          metaKeywords
          opengraphImage {
            mediaItemUrl
          }
          opengraphModifiedTime
          opengraphPublishedTime
          opengraphPublisher
          opengraphSiteName
          opengraphTitle
          opengraphType
          opengraphUrl
        }
        sections {
          sections {
            ... on WpPage_Sections_Sections_AppStoreCallout {
              text
              appStoreUrl
              fieldGroupName
              googlePlayUrl
              image {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      layout: CONSTRAINED,
                      placeholder: BLURRED
                    )
                  }
                }
              }
            }
            ... on WpPage_Sections_Sections_BadgesBlock {
              fieldGroupName
              heading
              subheading
            }
            ... on WpPage_Sections_Sections_VibesBlock {
              advancedOptions
              customVibes
              fieldGroupName
              heading
              style
              vibes {
                name
              }
            }
            ... on WpPage_Sections_Sections_SingCardsBlock {
              fieldGroupName
              singCards {
                fieldGroupName
                heading
                subheading
                cssClass
                style
                vibemapSuggests
                posts {
                  activityQuery {
                    slug
                  }
                  categoryQuery
                  distanceQuery
                  fieldGroupName
                  city
                  numOfPlaces
                  placeType
                  type
                  searchQuery
                  vibeQuery {
                    name
                    slug
                  }
                  subcategory
                  geoQuery {
                    latitude
                    longitude
                  }
                }
                showAll
                showAllLink
                showAllRelation
                showAllText
              }
            }
            ... on WpPage_Sections_Sections_NeighborhoodCardsBlock {
              fieldGroupName
              neighborhoodCards {
                automatic
                fieldGroupName
                heading
                subHeading
                vibeQuery {
                  name
                  slug
                }
              }
            }
            ... on WpPage_Sections_Sections_MapBlock {
              fieldGroupName
                customLocation
                fitBoundToMarkers
                heading
                height
                location {
                  ... on WpCity {
                    id
                    cityDetails {
                      fieldGroupName
                      subheading
                      colors {
                        color1
                        color2
                      }
                      description
                      radius
                      vibe
                      placemarker {
                        latitude
                        longitude
                        city
                        country
                        state
                      }
                      photos {
                        link
                        localFile {
                          publicURL
                        }
                      }
                      vibeset {
                        ... on WpVibeset {
                          id
                          title
                          databaseId
                          vibesetDetails {
                            gradientImage {
                              mediaItemUrl
                            }
                          }
                        }
                      }
                      vibes {
                        slug
                        name
                      }
                    }
                  }
                  ... on WpNeighborhood {
                    id
                    neighborhood {
                      map {
                        latitude
                        longitude
                      }
                    }
                  }
                }
                map {
                  latitude
                  longitude
                }
                heatmap
                directions
                map3d
                mapTheme
                markerStyle
                showMarkers
                width
                zoom
            }
            ... on WpPage_Sections_Sections_PostCardsBlock {
              automatic
              fieldGroupName
              heading
              subHeading
              vibeQuery {
                name
                slug
              }
            }
            ... on WpPage_Sections_Sections_TextWithImage {
              bodyText
              fieldGroupName
              heading
              layout
              textStyles
              style
              image {
                altText
                caption
                localFile {
                  publicURL
                }
              }
              vibeset {
                ... on WpVibeset {
                  id
                  vibesetDetails {
                    gradientImage {
                      mediaItemUrl
                    }
                    gradientVideo {
                      mediaItemUrl
                    }
                  }
                }
              }
              links {
                externalUrl
                fieldGroupName
                forceDownload
                linkText
                linkType
                email
                formId
                pageLink {
                  ... on WpPage {
                    id
                    uri
                  }
                  ... on WpPost {
                    id
                    uri
                  }
                }
                file {
                  id
                  localFile {
                    name
                    ext
                    publicURL
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
`
