import { helmetJsonLdProp } from "react-schemaorg";

export const eventToSchema = (event) => {
  const {
    address,
    description,
    aggregate_rating,
    end_date,
    hotspots_place,
    latitude,
    longitude,
    name,
    price,
    telephone,
    url,
    start_date,
    vibemap_images
  } = event.properties

  const streetAddress = address && address !== undefined
    ? address
    : hotspots_place?.properties?.address

  const schema = {
    '@context': "https://schema.org",
    '@type': "Event",
    address: address,
    description: description,
    //aggregate_rating: aggregate_rating,
    endDate: end_date,
    eventStatus: "https://schema.org/EventScheduled",
    image: vibemap_images[0] ? vibemap_images[0].original : '',
    latitude: latitude,
    longitude: longitude,
    location: {
      type: "Place",
      address: {
        type: "PostalAddress",
        name: streetAddress,
        streetAddress: streetAddress
      }
    },
    offers: {
      type: "Offer",
      availability: "https://schema.org/InStock",
      price: price ? price : 0,
      priceCurrency: "USD", // TODO: Fix for CA & MX
      url: url
    },
    name: name,
    startDate: start_date,
    //telephone: telephone,
    url: `https://app.vibemap.com/events/${event.id}`
  }

  return helmetJsonLdProp(schema)
}

export const eventsToSchema = (events) => {
  const schemaData = events.map(event => {
    //console.log('eventsToSchema ', events)
    const schemaData = eventToSchema(event)

    return schemaData
  })
  //console.log('schemaData ', schemaData)
  return schemaData
}

export const slugToURL = (link) => {
  const domain = `https://vibemap.com`
  let url = ''

  switch (link.type) {
    case 'activity':
      url = `${domain}/featured/${link.slug}`
      break;

    case 'city':
      url = `${domain}/cities/${link.slug}`
      break;

    case 'index':
      url = `${domain}/${link.slug}`
      break;

    default: break;
  }

  return url
}

export const linksToBreadcrumbs = (links) => {
  const breadcrumbItems = {
    "@context": "https://schema.org",
    "@type": "BreadcrumbList",
    "itemListElement": links.map((link, i) => {
      const url = slugToURL(link)

      return {
        "@type": "ListItem",
        "position": i + 1,
        "item": {
          "@id": url,
          "name": link.name
        }
      }
    })
  }

  return helmetJsonLdProp(breadcrumbItems)
}

export const placesToSchema = (places) => {
  const schemaPlaces = places.map(place => {

    const {
      address,
      description,
      aggregate_rating,
      latitude,
      longitude,
      name,
      telephone,
      url,
      vibemap_images
    } = place.properties
    return helmetJsonLdProp({
      '@context': "https://schema.org",
      '@type': "Place",
      address: address,
      aggregate_rating: aggregate_rating,
      image: vibemap_images[0] ? vibemap_images[0].original : '',
      latitude: latitude,
      longitude: longitude,
      name: name,
      telephone: telephone,
      url: `https://app.vibemap.com/details/${place.id}`
    })
  })

  return schemaPlaces
}

export const faqToSchema = (faq) => {
  const questions = faq.map(question => {

    return {
      '@context': "https://schema.org",
      '@type': "Question",
      name: question.qa.question,
      acceptedAnswer: {
        '@type': "Answer",
        text: question.qa.answer
      }
    }
  })

  const combined = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "mainEntity": questions
  }

  const schemaFaq = helmetJsonLdProp(combined)
  return schemaFaq
}

export const postToSchema = (post) => {
  const article = {
    "@context": "http://schema.org",
    "@type": "Article",
    name: post.title,
    headline: post.title,
    abstract: post.excerpt,
    articleBody: post.content,
    dateModified: post.date,
    datePublished: post.date,
    url: post.uri,
    author: post.author && post.author.node
      ? [{
        "@context": "http://schema.org",
        "@type": "Person",
        "name": post.author.name
      }]
      : ``
  }

  return helmetJsonLdProp(article)
}